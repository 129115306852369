export const state = () => ({
  triggerSearch: false,
  searchTerm: '',
  tagIds: null,
  ids: null,
  currentSort: null,
  isGridView: true,
  showControls: true,
  currentPageLP: [1],
  currentTabLP: 0
});

export const mutations = {
  triggerSearch(state, { searchTerm, tagIds, ids }) {
    state.searchTerm = searchTerm;
    state.tagIds = tagIds;
    state.ids = ids;
    state.triggerSearch = !state.triggerSearch;
  },

  resetSearch(state) {
    state.searchTerm = '';
    state.showControls = true;
  },

  setIsGridView(state, bool) {
    state.isGridView = bool;
  },

  setCurrentSort(state, sort) {
    state.currentSort = sort;
  },

  setShowControls(state, bool) {
    state.showControls = bool;
  },

  setCurrentPageLP(state, page) {
    state.currentPageLP[page.index] = page.page;
  },

  setCurrentTabLP(state, tab) {
    state.currentTabLP = tab;
  }
};
