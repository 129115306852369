export const state = () => ({
  prefersGridView: true,
  showCookieBanner: false,
  hasAcceptedCookies: null,
});

export const mutations = {
  setGridPreference(state, bool) {
    const showGrid = bool !== false;
    localStorage.setItem('prefersGridView', showGrid);
    state.prefersGridView = showGrid;
  },

  setHasAcceptedCookies(state, bool) {
    if (bool !== null) {
      if (typeof bool === 'string') {
        const newBool = bool === 'true';
        localStorage.setItem('hasAcceptedCookies', newBool);
        state.hasAcceptedCookies = Boolean(newBool);
      } else {
        localStorage.setItem('hasAcceptedCookies', bool);
        state.hasAcceptedCookies = Boolean(bool);
      }
      state.showCookieBanner = false;
    } else {
      // Empty localstorage -> show banner
      state.showCookieBanner = true;
    }
  },
};

export const getters = {
  showGrid(state) {
    return state.prefersGridView;
  },
};
