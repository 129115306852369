/* eslint-disable */
import Vue from 'vue';
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
  localize
} from 'vee-validate';
import {
  email,
  required,
  numeric,
  alpha_spaces
} from 'vee-validate/dist/rules.umd';
import validator from 'validator';

import { isValid, parse } from 'date-fns';

localize({
  de: {
    fields: {
      'validation.passwordConfirm': 'Die Passwörter stimmen nicht überein.'
    }
  }
});

export default function({ app }) {
  configure({
    defaultMessage: (field, values) => {
      values._field_ = app.i18n.t(`fieldNames.${field}`);

      if (
        field === 'firstname' &&
        app.i18n.t('validation.firstNameRequired') !==
          'validation.firstNameRequired'
      ) {
        return app.i18n.t('validation.firstNameRequired');
      }

      if (
        field === 'lastname' &&
        app.i18n.t('validation.lastNameRequired') !==
          'validation.lastNameRequired'
      ) {
        return app.i18n.t('validation.lastNameRequired');
      }

      if (
        (field === 'register-email' || field === 'email-login') &&
        app.i18n.t('validation.emailRequired') !== 'validation.emailRequired'
      ) {
        return app.i18n.t('validation.emailRequired');
      }

      if (
        (field === 'password' || field === 'password-login') &&
        app.i18n.t('validation.passwordRequired') !==
          'validation.passwordRequired'
      ) {
        return app.i18n.t('validation.passwordRequired');
      }

      return app.i18n.t(`validation.${values._rule_}`, values);
    }
  });
}

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

extend('required', required);
extend('email', email);
extend('numeric', numeric);
extend('alpha_spaces', alpha_spaces);

// TODO: Look at custom-error-types
// extend('password', password);

extend('password', {
  validate: (value) => {
    if (value.length < 8) return false;
    return true;
  }
});

extend('passwordConfirm', {
  validate: (value, { other }) => {
    if (!other) return true;
    return value === other;
  },

  params: [{ name: 'other', isTarget: true }]
});

extend('iban', {
  validate: (value) => {
    return validator.isIBAN(value);
  }
});

extend('bic', {
  validate: (value) => {
    if (value.length < 8) {
      return false;
    }
    if (value.match(/[^a-zA-Z0-9]/)) {
      return false;
    }
    if (value.length > 11) {
      return false;
    }

    return true;
  }
});

extend('date', {
  validate: (value) => {
    let date = parse(value, 'dd.MM.yyyy', new Date());
    return isValid(date);
  }
});
