/**
 * Middleware for AD-Users only
 * - testing new features alongside old ones
 */

/* eslint-disable */
export default function({ app, store, redirect }) {
  // Not Logged In
  const hasToken = !!app.$apolloHelpers.getToken();
  const indexPath = app.localePath('/');

  if (!hasToken) {
    return redirect(indexPath);
  }

  // Not AD User
  const userEmail = store.state.auth.user.email;
  if (
    !/@un-iq.de\s*$/.test(userEmail) &&
    !/@urlaubsguru.de\s*$/.test(userEmail)
  ) {
    return redirect(indexPath);
  }
}
