/**
 * Handles errors that occur during GraphQL operations.
 * @param {Object} error - The error object.
 * @param {Object} nuxtContext - The Nuxt.js context object.
 */
function errorHandler(error, nuxtContext) {
  const { graphQLErrors, networkError, operation, forward, extraInfo } = error;

  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      GraphQLErrorMessage.parseFrom(error, nuxtContext).log();
    });
  }

  if (networkError) {
    new NetworkError(networkError, nuxtContext).log();
  }

  if (operation) {
    // Unknown operation-object
    console.error('ApolloError - OPERATION: ', operation);
  }

  if (forward) {
    // Unknown forward-object
    console.error('ApolloError - FORWARD: ', forward);
  }

  if (extraInfo) {
    // Unknown extraInfo-object
    console.error('ApolloError - EXTRAINFO: ', extraInfo);
  }
}

class GraphQLErrorMessage {
  constructor(title, error, nuxtContext, logger = console.error) {
    this.title = title;
    this.message = error.message;
    this.errorcode = error.extensions.code;
    this.path = nuxtContext.route.path;
    this.query = this.#parseQueryAsString(nuxtContext.route.query);
    this.hostname = nuxtContext.req.headers.host;
    this.logger = logger;
  }

  /**
   * Logs the error details to the console.
   */
  log() {
    this.logger(
      `${this.title}\n`,
      `${this.message}\n`,
      `code: ${this.errorcode}\n`,
      `url: ${this.hostname}${this.path}${this.query}`
    );
  }

  /**
   * Parses the given error and returns a new GraphQLErrorMessage instance.
   * @param {Error} error - The error to parse.
   * @param {Object} nuxtContext - The Nuxt.js context object.
   * @returns {GraphQLErrorMessage} A new GraphQLErrorMessage instance.
   */
  static parseFrom(error, nuxtContext) {
    let title;

    switch (error.extensions?.code) {
      case 'GRAPHQL_PARSE_FAILED':
        title = 'The GraphQL operation string contains a syntax error.';
        break;

      case 'GRAPHQL_VALIDATION_FAILED':
        title =
          'The GraphQL operation is not valid against the server`s schema.';
        break;

      case 'BAD_USER_INPUT':
        title =
          'The GraphQL operation includes an invalid value for a field argument.';
        break;

      case 'PERSISTED_QUERY_NOT_FOUND':
        title =
          'A client sent the hash of a query string to execute via automatic persisted queries, but the query was not in the APQ cache.';
        break;

      case 'PERSISTED_QUERY_NOT_SUPPORTED':
        title =
          'A client sent the hash of a query string to execute via automatic persisted queries, but the server has disabled APQ.';
        break;

      case 'OPERATION_RESOLUTION_FAILURE':
        title =
          'Request parsed successfully, but server couldn`t determine which operation to execute due to missing operationName or a non-included named operation.';
        break;

      case 'BAD_REQUEST':
        title =
          'An error occurred before your server could attempt to parse the given GraphQL operation.';
        break;

      case 'INTERNAL_SERVER_ERROR':
      default:
        title = 'An unspecified error occurred.';
        break;
    }

    return new GraphQLErrorMessage(title, error, nuxtContext);
  }

  /**
   * Returns a string representation of the given query object.
   *
   * @param {Object} query - The query object to parse.
   * @returns {string} A string representation of the query object.
   */
  #parseQueryAsString(query) {
    if (Object.keys(query).length < 1) return '';

    const queryAsString = Object.keys(query).reduce((acc, key) => {
      return `${acc}&${key}=${query[key]}`;
    }, '');

    return `?${queryAsString}`;
  }
}

class NetworkError {
  // private regex variable to check if the body is HTML
  #isHtmlRegex = /<(br|basefont|hr|input|source|frame|param|area|meta|!--|col|link|option|base|img|wbr|!DOCTYPE).*?>|<(a|abbr|acronym|address|applet|article|aside|audio|b|bdi|bdo|big|blockquote|body|button|canvas|caption|center|cite|code|colgroup|command|datalist|dd|del|details|dfn|dialog|dir|div|dl|dt|em|embed|fieldset|figcaption|figure|font|footer|form|frameset|head|header|hgroup|h1|h2|h3|h4|h5|h6|html|i|iframe|ins|kbd|keygen|label|legend|li|map|mark|menu|meter|nav|noframes|noscript|object|ol|optgroup|output|p|pre|progress|q|rp|rt|ruby|s|samp|script|section|select|small|span|strike|strong|style|sub|summary|sup|table|tbody|td|textarea|tfoot|th|thead|time|title|tr|track|tt|u|ul|var|video).*?<\/\2>/i;

  constructor(error, nuxtContext, logger = console.error) {
    this.hostname = nuxtContext.req.headers.host;
    this.path = nuxtContext.route.path;
    this.query = nuxtContext.route.query;
    this.error = error;
    this.logger = logger;
  }

  /**
   * Logs the error message and path if available.
   */
  log() {
    if (this.#isObjectType(this.error)) {
      this.logger('Apollo Network Error:\n', this.#buildLogObject());
    } else {
      this.logger('Apollo Network Error:', this.error, 'Path:', this.path);
    }
  }

  /**
   * Checks if the given parameter is an object type.
   * @param {*} error - The parameter to check.
   * @returns {boolean} - Returns true if the parameter is an object type, otherwise false.
   */
  #isObjectType(error) {
    return typeof error === 'object';
  }

  /**
   * Builds a log object based on the current error instance.
   * @returns {string} A JSON stringified log object.
   */
  #buildLogObject() {
    const error = this.error;
    const logObject = {};

    if (error.cause) {
      logObject.message = this.#getErrorMessage(error.cause);
    } else {
      Object.keys(error).forEach((key) => {
        if (key === 'bodyText') {
          logObject[key] = this.#isHtmlBody(error.bodyText)
            ? 'Body is HTML - cannot log the content.'
            : error.bodyText;
        } else {
          logObject[key] = error[key];
        }
      });
    }

    logObject.url = `${this.hostname}${this.path}${this.#parseQueryAsString(
      this.query
    )}`;

    return JSON.stringify(logObject, null, 2);
  }

  /**
   * Returns an error message based on the provided error code.
   * @param {Object} cause - The error object containing a code property.
   * @returns {string} - The error message corresponding to the provided error code.
   */
  #getErrorMessage(cause) {
    const errorMessages = {
      ECONNREFUSED: 'The server refused the connection.',
      ECONNRESET: 'The server reset the connection.',
      ECONNABORTED: 'The connection was aborted.',
      ENOTFOUND: 'The server could not be found.',
      EPIPE: 'The connection was broken.',
      EAI_AGAIN: 'The DNS lookup timed out.'
    };

    return (
      errorMessages[cause.code] || `Unknown error with code: ${cause.code}`
    );
  }

  /**
   * Checks if the given body is HTML.
   * @param {string} body - The body to check.
   * @returns {boolean} - True if the body is HTML, false otherwise.
   */
  #isHtmlBody(body) {
    return this.#isHtmlRegex.test(body);
  }

  /**
   * Returns a string representation of the given query object.
   *
   * @param {Object} query - The query object to parse.
   * @returns {string} A string representation of the query object.
   */
  #parseQueryAsString(query) {
    const queryAsString = Object.keys(query).reduce((acc, key) => {
      return `${acc}&${key}=${query[key]}`;
    }, '');

    if (queryAsString === '') return '';
    return `?${queryAsString}`;
  }
}

export default errorHandler;
