export default function(req, res, next) {
  const path = req.url;
  const host = req.headers['host'];

  if (host.includes('.de') || host.includes('.at') || host.includes('.ch')) {
    if (path == '/deals/') {
      res.writeHead(301, {
        Location: '/angebote/'
      });
      res.end();
      return;
    } else if (path.startsWith('/deal/')) {
      res.writeHead(301, {
        Location: path.replace('/deal/', '/angebot/')
      });
      res.end();
      return;
    }
  } else if (host.includes('.nl')) {
    if (path == '/deals/') {
      res.writeHead(301, {
        Location: '/aanbiedingen/'
      });
      res.end();
      return;
    } else if (path.startsWith('/deal/')) {
      res.writeHead(301, {
        Location: path.replace('/deal/', '/aanbieding/')
      });
      res.end();
      return;
    }
  }

  next();
}
