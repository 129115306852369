export default function({ app, redirect, route }) {
  const hasToken = !!app.$apolloHelpers.getToken();
  const loginPath = app.localePath('auth-login');

  if (!hasToken) {
    return redirect(loginPath, {
      redirect: encodeURIComponent(route.fullPath),
    });
  }
}
