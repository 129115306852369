export default ({ app }, inject) => {
  inject('decorateUrl', urlString => {
    const ga = window.ga;
    if (!ga) return urlString;
    let tracker;
    if (ga && typeof ga.getAll === 'function') {
      tracker = ga.getAll()[0]; // Uses the first tracker created on the page
      urlString = new window.gaplugins.Linker(tracker).decorate(urlString);
    }
    return urlString;
  });
};
