<template>
  <HTTPError :error="error" />
</template>

<script>
export default {
  components: {
    HTTPError: () => import('@/components/ui/HTTPError'),
  },
  layout: 'error', // you can set a custom layout for the error page
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
