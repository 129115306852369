if (window.FB) {
  // FB script already loaded, init the SDK
  window.FB.init({
    appId: '457119208294229',
    xfbml: true,
    version: 'v16.0'
  });
} else {
  // Wait for FB script to load to init the SDK
  window.fbAsyncInit = function() {
    FB.init({
      appId: '457119208294229',
      xfbml: true,
      version: 'v16.0'
    });
  };
}
