const middleware = {}

middleware['isAppView'] = require('../middleware/isAppView.js')
middleware['isAppView'] = middleware['isAppView'].default || middleware['isAppView']

middleware['isAuthenticated'] = require('../middleware/isAuthenticated.js')
middleware['isAuthenticated'] = middleware['isAuthenticated'].default || middleware['isAuthenticated']

middleware['isGuest'] = require('../middleware/isGuest.js')
middleware['isGuest'] = middleware['isGuest'].default || middleware['isGuest']

middleware['isInternal'] = require('../middleware/isInternal.js')
middleware['isInternal'] = middleware['isInternal'].default || middleware['isInternal']

middleware['redirects'] = require('../middleware/redirects.js')
middleware['redirects'] = middleware['redirects'].default || middleware['redirects']

middleware['trackingCookies'] = require('../middleware/trackingCookies.js')
middleware['trackingCookies'] = middleware['trackingCookies'].default || middleware['trackingCookies']

export default middleware
