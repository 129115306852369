import Vue from 'vue';

if (window.Tracify != null) {
  var tracify = Tracify({
    csid: '09b4b5d2-9428-4d32-adb5-04c07506bcdb',
    fingerPrint: true
  });

  tracify.capturePageView();

  Vue.use(tracify);
}
