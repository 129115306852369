<template>
  <div
    class="wrapper"
    :class="
      $route.path.includes('/deal/') || $route.path.includes('/angebot/') || $route.path.includes('/aanbieding/')
        ? 'deal-page'
        : ''
    "
  >
    <Navbar />
    <!-- Main -->
    <div class="main-content columns">
      <!-- Left Column -->
      <div class="column left-gutter" />

      <!-- Main Column -->
      <div class="column main-column">
        <nuxt />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    Navbar: () => import('@/components/partials/Navbar.vue')
  },
  head() {
    return {
      meta: [
        { hid: 'robots', name: 'robots', content: 'noindex' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' }
      ]
    };
  }
};
</script>
