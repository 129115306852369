import Vue from 'vue';

import * as components from '@uniqgmbh/holidayguru-components';

// Register Lib Components
export default ctx => {
  // Install components
  Object.keys(components).forEach(componentName => {
    Vue.component(componentName, components[componentName]);
  });
};
