/**
 * Middleware for setting tracking cookies
 *
 * -> Get UTM parameters from URL
 * -> Get FB-parameters from Cookies
 *
 * Checks current forwarder-cookie and sets new one only for new campaigns
 */
/* eslint-disable */
export default function({ $cookies, store, req, query }) {
  if (store.state.hasTrackingInfo) return;

  const domain = getDomain(req);

  const utmSource = query.utm_source;
  const utmMedium = query.utm_medium;
  const utmCampaign = query.utm_campaign;
  const utmTerm = query.utm_term;
  const utmContent = query.utm_content;
  const gclid = query.gclid;
  const fbclid = query.fbclid;

  const landingPageUrl = process.server
    ? req.headers.host
    : window.location.pathname;

  // User ID
  const { user } = store.state.auth;

  // Google Analytics ID
  const googleAnalyticsClientId = $cookies.get('_gid');

  //   FB params from cookie
  const fbp = $cookies.get('_fbp');
  const fbc = $cookies.get('_fbc');

  // Check current Cookie -> Only set new cookie for new/different campaigns
  const currentCookie = $cookies.get('hg-forwarder-cookie');
  if (
    currentCookie &&
    currentCookie.fbp === fbp &&
    currentCookie.fbc === fbc &&
    currentCookie.fbclid === fbclid &&
    currentCookie.utmSource === utmSource &&
    currentCookie.utmContent === utmContent &&
    currentCookie.userId === (user && user.id)
  ) {
    store.commit('setHasTrackingInfo', true);
    return;
  }

  const trackingCookie = {
    utmSource,
    utmMedium,
    utmCampaign,
    utmTerm,
    utmContent,
    landingPageUrl,
    gclid,
    fbp,
    fbc,
    fbclid,
    googleAnalyticsClientId,
    userId: user ? user.id : null,
  };

  // Set new forwarder cookie
  $cookies.set('hg-forwarder-cookie', JSON.stringify(trackingCookie), {
    path: '/',
    domain,
    maxAge: 60 * 60 * 24 * 30, // 30 days maxAge
  });
  store.commit('setHasTrackingInfo', true);
}

const getDomain = req => {
  const origin = process.server ? req.headers.host : window.location.origin;
  const splitDomain = origin.split('.');
  const domain =
    splitDomain.length > 1
      ? splitDomain[splitDomain.length - 2] +
        '.' +
        splitDomain[splitDomain.length - 1]
      : 'localhost';

  return domain;
};

const getUrl = () => {
  if (typeof window === 'undefined') return;
  var url = new URL(window.location.href);
  var deletedParams = [
    '_ga',
    '_gl',
    'utm_source',
    'gclid',
    'fbclid',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'utm_term',
    'partner_campaign',
    'f',
    'campaign',
    'device',
    'agent',
    'newApp',
    'appView',
    'original-origin',
    'epik',
  ];

  deletedParams.forEach(function(param) {
    if (url.searchParams.get(param)) {
      url.searchParams.delete(param);
    }
  });

  return url.pathname + url.search + url.hash;
};
