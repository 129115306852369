export const state = () => ({
  allowAds: false,
  allowGoogleAds: false,
  allowGoogleAnalytics: false,
  allowGoogleOptimize: false,
  allowAdup: false,
  allowHotjar: false,
  allowFacebook: false,
  allowTikTok: false,
  allowCriteo: false,
  allowRTBHouse: false,
  allowMicrosoft: false,
  allowInfonline: false,
  allowLinkedIn: false,
  allowShoppingMinds: false,
  allowSelligent: false,
  allowGenesis: false,
  allowSpotify: false,
  allowAwin: false,
  stroeerLoaded: false,
  stroeerConsentAvailable: false,
  stroeerIsCheckingConsent: false,
  stroeerTriggerAdSlots: undefined,
  adupLoaded: false
});

export const mutations = {
  setAllowAds(state, bool) {
    state.allowAds = bool;
  },

  setAllowAdup(state, bool) {
    state.allowAdup = bool;
  },

  setAllowHotjar(state, bool) {
    state.allowHotjar = bool;
  },

  setAllowInfonline(state, bool) {
    state.allowInfonline = bool;
  },

  setAllowShoppingMinds(state, bool) {
    state.allowShoppingMinds = bool;
  },

  setAllowSelligent(state, bool) {
    state.allowSelligent = bool;
  },

  setAllowGenesis(state, bool) {
    state.allowGenesis = bool;
  },

  setAllowSpotify(state, bool) {
    state.allowSpotify = bool;
  },

  setAllowAwin(state, bool) {
    state.allowAwin = bool;
  },

  setStroeerLoaded(state, bool) {
    state.stroeerLoaded = bool;
  },
  setStroeerConsentAvailable(state, bool) {
    state.stroeerConsentAvailable = bool;
  },
  setStroeerIsCheckingConsent(state, bool) {
    state.stroeerIsCheckingConsent = bool;
  },
  setStroeerTriggerAdSlots(state, timestamp) {
    state.stroeerTriggerAdSlots = timestamp;
  },
  setAdupLoaded(state, bool) {
    state.adupLoaded = bool;
  },

  setAllowGoogleAds(state, bool) {
    state.allowGoogleAds = bool;
  },

  setAllowGoogleAnalytics(state, bool) {
    state.allowGoogleAnalytics = bool;
  },

  setAllowGoogleOptimize(state, bool) {
    state.allowGoogleOptimize = bool;
  },

  setAllowFacebook(state, bool) {
    state.allowFacebook = bool;
  },

  setAllowTikTok(state, bool) {
    state.allowTikTok = bool;
  },

  setAllowCriteo(state, bool) {
    state.allowCriteo = bool;
  },

  setAllowRTBHouse(state, bool) {
    state.allowRTBHouse = bool;
  },

  setAllowLinkedIn(state, bool) {
    state.allowLinkedIn = bool;
  },

  setAllowMicrosoft(state, bool) {
    state.allowMicrosoft = bool;
  }
};
