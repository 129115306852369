export default ctx => {
  const { locale } = ctx.app.i18n
  
  if (locale === 'at') {
    (function (s, m, a, r, t) {
      r = s.createElement(m);
      r.async = true;
      r.src = a;
      t = s.getElementsByTagName(m)[0];
      t.parentNode.insertBefore(r, t);
    })(document, 'script', '//script.shoppingminds.com/urlaubsguru_at/index.js?=' + new Date().getTime());
  } else if (locale === 'es') {
    (function (s, m, a, r, t) {
      r = s.createElement(m);
      r.async = true;
      r.src = a;
      t = s.getElementsByTagName(m)[0];
      t.parentNode.insertBefore(r, t);
    })(document, 'script', '//script.shoppingminds.com/holidayguru_es/index.js?=' + new Date().getTime());
  }
}
