/**
 * Middleware for guest-only routes
 * - eg login/register
 */

/* eslint-disable */
export default function({ app, redirect }) {
  const hasToken = !!app.$apolloHelpers.getToken();
  const indexPath = app.localePath('/');
  if (hasToken) {
    return redirect(indexPath);
  }
}
