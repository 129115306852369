import {
  IntrospectionFragmentMatcher,
  InMemoryCache,
} from 'apollo-cache-inmemory';
import introspectionQueryResultData from '../fragmentTypes.json';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

/**
 * Apollo Config
 * @param {object} ctx
 */
export default function({ $config, app }) {
  let { locale } = app.i18n;

  return {
    httpEndpoint: $config.GRAPHQL_GATEWAY_URI,
    ssr: true,
    cache: new InMemoryCache({ fragmentMatcher }),
    tokenName: `${locale}-apollo-token`,
    httpLinkOptions: {
      headers: {
        'x-locale': locale,
        'apollographql-client-name': 'WebApp',
        'apollographql-client-version': $config.BUILD_ID,
        ...(($config.ENABLE_GRAPHCDN === false || $config.ENABLE_GRAPHCDN === "false") && {
          'x-disable-graphcdn': '1'
        })
      }
    }
  };
}
