/* eslint-disable */
export const state = () => ({
  pendingEvents: [],
  currentEvent: undefined,
  hasSentConsent: false,
});

export const mutations = {
  setHasSentConsent(state, bool) {
    state.hasSentConsent = bool;
  },
  addPendingEvent(state, event) {
    state.pendingEvents.push(event);
  },
  shiftAndPushPendingEvents(state) {
    state.currentEvent = state.pendingEvents.shift();
    this.$gtm.push(state.currentEvent);
  },
};
