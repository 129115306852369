export const state = () => ({
  hasTrackingCookie: false,
});

export const mutations = {
  setHasTrackingCookie(state, bool) {
    state.hasTrackingCookie = bool;
  },
};

/* eslint-disable */
export const actions = {
  /**
   * Initialize Tracking cookie on site init
   * https://uniq.atlassian.net/wiki/spaces/TRAK/pages/837419009/Last+Click+Cookie+Detaildefinition
   */
  init({ commit }, route) {
    const { query } = route;
    log('Tracking -- Query ', query);
    const { $cookies } = this.app;
    const { locale } = this.app.i18n;

    const domain = getDomain();
    const urlPath = getUrl();
    const referrer = getReferrer();
    const landingPageUrl = domain + urlPath;

    const utmSource = query.utm_source;
    const utmMedium = query.utm_medium;
    const utmCampaign = query.utm_campaign;
    const utmTerm = query.utm_term;
    const utmContent = query.utm_content;
    const gclid = query.gclid;
    const fbclid = query.fbclid;
    // User ID
    const { user } = this.app.store.state.auth;

    // Google Analytics ID
    const googleAnalyticsClientId = getGoogleClientId();
    const googleAnalyticsV4ClientId = cleanupGoogleClientV4Id(
      $cookies.get(getCookieStringV4(locale)),
    );
    // eslint-disable-next-line
    console.log('Analyicis IDS', googleAnalyticsClientId);
    // FB params from cookie
    const fbp = $cookies.get('_fbp');
    const fbc = $cookies.get('_fbc');

    // Check current Cookie -> Only set new cookie for new/different campaigns
    const firstClickCookie = $cookies.get('hg-first-click-cookie');
    const currentLastClickCookie = $cookies.get('hg-last-click-cookie') || {};
    const newLastClickCookie = { ...currentLastClickCookie };

    if (
      (!newLastClickCookie.googleAnalyticsClientId &&
        googleAnalyticsClientId) ||
      newLastClickCookie.googleAnalyticsClientId !== googleAnalyticsClientId
    ) {
      newLastClickCookie.googleAnalyticsClientId = googleAnalyticsClientId;
    }

    if (
      (!newLastClickCookie.googleAnalyticsV4ClientId &&
        googleAnalyticsV4ClientId) ||
      newLastClickCookie.googleAnalyticsV4ClientId !== googleAnalyticsV4ClientId
    ) {
      newLastClickCookie.googleAnalyticsV4ClientId = googleAnalyticsV4ClientId;
    }

    if (newLastClickCookie.fbp !== fbp) {
      newLastClickCookie.fbp = fbp;
    }

    if (newLastClickCookie.fbc !== fbc) {
      newLastClickCookie.fbc = fbc;
    }

    if (gclid && newLastClickCookie.gclid !== gclid) {
      newLastClickCookie.gclid = gclid;
    }

    if (fbclid && newLastClickCookie.fbclid !== fbclid) {
      newLastClickCookie.fbclid = fbclid;
    }

    if (
      landingPageUrl &&
      newLastClickCookie.landingPageUrl !== landingPageUrl
    ) {
      newLastClickCookie.landingPageUrl = landingPageUrl;
    }

    log('Current Last Cookie 🍪:', currentLastClickCookie);

    // New Cookie
    if (user) {
      newLastClickCookie.userId = user.id;
    }

    let newUtmSource, newUtmMedium;

    if (gclid) {
      newUtmSource = 'google ads';
      newUtmMedium = 'cpc';
    } else if (utmSource && utmMedium) {
      newUtmSource = utmSource;
      newUtmMedium = utmMedium;
    } else if (utmSource) {
      newUtmSource = utmSource;
      newUtmMedium = 'none';
    } else if (utmMedium) {
      newUtmSource = 'none';
      newUtmMedium = utmMedium;
    } else if (referrer.includes('google')) {
      newUtmSource = 'google';
      newUtmMedium = 'organic';
    } else if (referrer.includes('bing')) {
      newUtmSource = 'bing';
      newUtmMedium = 'organic';
    } else if (referrer.includes('duckduckgo')) {
      newUtmSource = 'duckduckgo';
      newUtmMedium = 'organic';
    } else if (referrer.includes('suche.t-online.de')) {
      newUtmSource = 'suche.t-online.de';
      newUtmMedium = 'organic';
    } else if (referrer.includes('suche.web.de')) {
      newUtmSource = 'suche.web.de';
      newUtmMedium = 'organic';
    } else if (referrer.includes('ecosia')) {
      newUtmSource = 'ecosia';
      newUtmMedium = 'organic';
    } else if (referrer.includes('yahoo')) {
      newUtmSource = 'yahoo';
      newUtmMedium = 'organic';
    } else if (referrer.includes('facebook')) {
      newUtmSource = 'facebook';
      newUtmMedium = 'organic';
    } else if (referrer.includes('instagram')) {
      newUtmSource = 'instagram';
      newUtmMedium = 'organic';
    } else if (referrer.includes('pinterest')) {
      newUtmSource = 'pinterest';
      newUtmMedium = 'organic';
    } else if (referrer.includes('tiktok')) {
      newUtmSource = 'tiktok';
      newUtmMedium = 'organic';
    } else if (
      !referrer.includes('urlaubsguru') &&
      !referrer.includes('holidayguru') &&
      referrer.length
    ) {
      newUtmSource = referrer;
      newUtmMedium = 'referral';
    }
    // Direct/None if no source/medium present
    if (!utmSource && !utmMedium) {
      if (
        !currentLastClickCookie.utmSource &&
        !currentLastClickCookie.utmMedium
      ) {
        newUtmSource = 'direct';
        newUtmMedium = 'none';
      }
    }

    /**
     * Overwrite Campaign Data
     * -> Check new source/medium against currentCookie Data
     * -> overwrite or delete field (because of deep copy)
     */
    if (
      (newUtmSource || newUtmMedium || utmCampaign || utmContent || utmTerm) &&
      (newUtmSource !== currentLastClickCookie.utmSource ||
        newUtmMedium !== currentLastClickCookie.utmMedium ||
        utmCampaign !== currentLastClickCookie.utmCampaign ||
        utmTerm !== currentLastClickCookie.utmTerm ||
        utmContent !== currentLastClickCookie.utmContent)
    ) {
      if (newUtmSource) {
        newLastClickCookie.utmSource = newUtmSource;
      } else {
        delete newLastClickCookie.utmSource;
      }
      if (newUtmMedium) {
        newLastClickCookie.utmMedium = newUtmMedium;
      } else {
        delete newLastClickCookie.utmMedium;
      }
      if (utmCampaign) {
        newLastClickCookie.utmCampaign = utmCampaign;
      } else {
        delete newLastClickCookie.utmCampaign;
      }
      if (utmContent) {
        newLastClickCookie.utmContent = utmContent;
      } else {
        delete newLastClickCookie.utmContent;
      }
      if (utmTerm) {
        newLastClickCookie.utmTerm = utmTerm;
      } else {
        delete newLastClickCookie.utmTerm;
      }
    }

    log('New Last Cookie 🍪:', newLastClickCookie);

    if (
      JSON.stringify(newLastClickCookie) ===
      JSON.stringify(currentLastClickCookie)
    ) {
      log('DO NOT OVERWRITE COOKIE!!');
      commit('setHasTrackingCookie', true);
      return;
    }
    log('OVERWRITE COOKIE!!');

    $cookies.set('hg-last-click-cookie', JSON.stringify(newLastClickCookie), {
      path: '/',
      domain,
      maxAge: 60 * 60 * 24 * 61, // 61 days maxAge
    });

    if (!firstClickCookie) {
      $cookies.set(
        'hg-first-click-cookie',
        JSON.stringify(newLastClickCookie),
        {
          path: '/',
          domain,
          maxAge: 60 * 60 * 24 * 61, // 61 days maxAge
        },
      );
    }
    commit('setHasTrackingCookie', true);
  },

  clickout({ commit }) {
    const { $cookies } = this.app;
    const { locale } = this.app.i18n;
    const currentLastClickCookie = $cookies.get('hg-last-click-cookie');

    const domain = getDomain();
    const urlPath = getUrl();
    let clickoutUrl = domain + urlPath;

    // get Google Analytics ID
    const googleAnalyticsClientId = getGoogleClientId();
    const googleAnalyticsV4ClientId = cleanupGoogleClientV4Id(
      $cookies.get(getCookieStringV4(locale)),
    );
    // get FB params from cookie
    const fbp = $cookies.get('_fbp');
    const fbc = $cookies.get('_fbc');

    // check if clickoutUrl contains 'https://www.' and add it if not
    if (!clickoutUrl.includes('https://www.')) {
      clickoutUrl = 'https://www.' + clickoutUrl;
    }

    const newCookie = {
      ...currentLastClickCookie,
      clickoutPageUrl: clickoutUrl,
    };
    // check if google / fb IDs have changed and update them
    if (
      (!newCookie.googleAnalyticsClientId && googleAnalyticsClientId) ||
      newCookie.googleAnalyticsClientId !== googleAnalyticsClientId
    ) {
      newCookie.googleAnalyticsClientId = googleAnalyticsClientId;
    }

    if (
      (!newCookie.googleAnalyticsV4ClientId && googleAnalyticsV4ClientId) ||
      newCookie.googleAnalyticsV4ClientId !== googleAnalyticsV4ClientId
    ) {
      newCookie.googleAnalyticsV4ClientId = googleAnalyticsV4ClientId;
    }

    if (newCookie.fbp !== fbp) {
      newCookie.fbp = fbp;
    }

    if (newCookie.fbc !== fbc) {
      newCookie.fbc = fbc;
    }

    $cookies.set('hg-last-click-cookie', JSON.stringify(newCookie), {
      path: '/',
      domain,
      maxAge: 60 * 60 * 24 * 61, // 61 days maxAge
    });
  },
};

const getDomain = () => {
  const origin = window.location.origin;
  const splitDomain = origin.split('.');
  const domain =
    splitDomain.length > 1
      ? splitDomain[splitDomain.length - 2] +
        '.' +
        splitDomain[splitDomain.length - 1]
      : 'localhost';

  return domain;
};

const getUrl = () => {
  const url = new URL(window.location.href);
  const deletedParams = [
    '_ga',
    '_gl',
    'utm_source',
    'gclid',
    'fbclid',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'utm_term',
    'partner_campaign',
    'f',
    'campaign',
    'device',
    'agent',
    'newApp',
    'appView',
    'epik',
    'original-origin',
    'M_BT',
    'email-login',
    'password-login',
    'hash',
    'external_browser_redirect',
    'pk_vid',
  ];

  deletedParams.forEach(function(param) {
    if (url.searchParams.get(param)) {
      url.searchParams.delete(param);
    }
  });

  return url.pathname + url.search + url.hash;
};

const getReferrer = () => {
  return document.referrer;
};

const getGoogleClientId = () => {
  const ga = window.ga;

  let clientId;
  if (!ga) {
    return clientId;
  }
  let tracker;
  if (ga && typeof ga.getAll === 'function') {
    tracker = ga.getAll()[0]; // Uses the first tracker created on the page
    clientId = tracker.get('clientId');
  }
  return clientId;
};

/**
 * resolve Google Client ID V4 Cookie String from locale
 * @param {string} locale - locale string
 */
const getCookieStringV4 = locale => {
  return locale.toUpperCase() + '_GA4_ga';
};

/**
 * cleanup Google Client ID V4
 * @param {string} str - Google Client ID V4 from Cookie
 * remove every character before and including the second dot from the GAV4 ClientID
 * Example: 'GA1.1.1154599144.1634110348' will be '1154599144.1634110348'
 * see Ticket https://uniq.atlassian.net/browse/DEV-877
 */
const cleanupGoogleClientV4Id = str => {
  if (!str) return str;
  const regexp = /(\..\.)(.*)/g;
  const resultArr = [...str.matchAll(regexp)];
  return resultArr.length ? resultArr[0][2] : str;
};

const log = (...content) => {
  // if (this.$config.DEBUG_LOGS) {
  /* eslint-disable */
  console.log(
    '%cHG-Tracking:',
    'color:teal; background-color:white; border-radius: 4px; padding: 2px;',
    ...content,
  );
  /* eslint-enable */
  // }
};
