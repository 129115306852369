import authLocalMutation from '@/apollo/mutations/auth/authLocal';
import authLdapMutation from '@/apollo/mutations/auth/authLdap';
import authAppleMutation from '@/apollo/mutations/auth/authApple';
import authFacebookMutation from '@/apollo/mutations/auth/authFacebook';
import authGoogleMutation from '@/apollo/mutations/auth/authGoogle';
import registerMutation from '@/apollo/mutations/auth/register';
import meQuery from '@/apollo/queries/auth/me';

const isEmailInternal = (email) => {
  if (
    /@urlaubsguru.de\s*$/.test(email) ||
    /@urlaubsguru.at\s*$/.test(email) ||
    /@holidayguru.ch\s*$/.test(email) ||
    /@holidayguru.es\s*$/.test(email) ||
    /@holidayguru.nl\s*$/.test(email)
  ) {
    return true;
  }
  return false;
};

export const state = () => ({
  user: null,
  isInternalUser: false,
  loggedIn: false
});

export const mutations = {
  setUser(state, user) {
    state.user = user;
    if (user && !user.profile.firstName) {
      state.user.profile.firstName = '';
    }
    if (user && !user.profile.lastName) {
      state.user.profile.lastName = '';
    }
  },

  setLoggedIn(state, bool) {
    state.loggedIn = bool;
  },

  setIsInternalUser(state, bool) {
    state.isInternalUser = bool;
  }
};

export const actions = {
  async appleLogin({ commit, dispatch }, idToken) {
    const client = this.app.apolloProvider.defaultClient;
    const helpers = this.app.$apolloHelpers;

    try {
      const { data } = await client.mutate({
        mutation: authAppleMutation,
        variables: { identityToken: idToken }
      });

      const token = data.authApple.token;

      await helpers.onLogin(token);

      commit('setLoggedIn', true);

      await dispatch('getUser');
    } catch (e) {
      console.warn('Error', e.graphQLErrors); // eslint-disable-line no-console
      commit('setIsInternalUser', false);
      throw e.graphQLErrors;
    }
  },

  async facebookLogin({ commit, dispatch }, accessToken) {
    const client = this.app.apolloProvider.defaultClient;
    const helpers = this.app.$apolloHelpers;

    try {
      const { data } = await client.mutate({
        mutation: authFacebookMutation,
        variables: { accessToken }
      });

      const token = data.authFacebook.token;

      await helpers.onLogin(token);

      commit('setLoggedIn', true);

      await dispatch('getUser');
    } catch (e) {
      console.warn('Error', e.graphQLErrors); // eslint-disable-line no-console
      commit('setIsInternalUser', false);
      throw e.graphQLErrors;
    }
  },

  async googleLogin({ commit, dispatch }, idToken) {
    const client = this.app.apolloProvider.defaultClient;
    const helpers = this.app.$apolloHelpers;

    try {
      const { data } = await client.mutate({
        mutation: authGoogleMutation,
        variables: { idToken }
      });

      const token = data.authGoogle.token;

      await helpers.onLogin(token);

      commit('setLoggedIn', true);

      await dispatch('getUser');
    } catch (e) {
      console.warn('Error', e.graphQLErrors); // eslint-disable-line no-console
      commit('setIsInternalUser', false);
      throw e.graphQLErrors;
    }
  },

  async login({ commit, dispatch }, credentials) {
    const client = this.app.apolloProvider.defaultClient;
    const helpers = this.app.$apolloHelpers;
    const isInternal = isEmailInternal(credentials.email);

    let currentLoginMutation = authLocalMutation;

    if (isInternal) {
      currentLoginMutation = authLdapMutation;
    }

    try {
      const { data } = await client.mutate({
        mutation: currentLoginMutation,
        variables: credentials
      });

      let token;
      if (currentLoginMutation === authLocalMutation) {
        token = data.authLocal.token;
      } else {
        token = data.authLdap.token;
      }

      await helpers.onLogin(token);

      commit('setLoggedIn', true);
      if (isInternal) {
        commit('setIsInternalUser', true);
      }
      await dispatch('getUser');
    } catch (e) {
      console.warn('Error', e.graphQLErrors); // eslint-disable-line no-console
      commit('setIsInternalUser', false);
      throw e.graphQLErrors;
    }
  },

  async register({ commit, dispatch }, credentials) {
    const client = this.app.apolloProvider.defaultClient;
    try {
      await client.mutate({
        mutation: registerMutation,
        variables: credentials
      });
    } catch (e) {
      throw e.graphQLErrors;
    }
  },

  async getUser({ commit }) {
    const client = this.app.apolloProvider.defaultClient;

    const {
      data: { me }
    } = await client.query({
      prefetch: false,
      fetchPolicy: 'no-cache',
      query: meQuery
    });

    commit('setUser', me);
    commit('setLoggedIn', true);
    this.$cookies.set('hg-user-id', me.id);

    if (isEmailInternal(me.email)) {
      commit('setIsInternalUser', true);
    }
  },

  async logout({ commit }) {
    const { $apolloHelpers } = this.app;
    const client = this.app.apolloProvider.defaultClient;

    await client.cache.data.clear();
    await $apolloHelpers.onLogout();

    commit('setUser', null);
    commit('setLoggedIn', false);
    commit('setIsInternalUser', false);
  }
};

export const getters = {
  isAuthenticated(state) {
    return state.loggedIn;
  },

  loggedInUser(state) {
    return state.user;
  },

  isInternalUser(state) {
    return state.isInternalUser;
  }
};
